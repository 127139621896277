@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply antialiased text-gray-800;
  }
}

@layer components {
  .btn-primary {
    @apply bg-secondary text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors;
  }
  
  .btn-secondary {
    @apply bg-white text-secondary px-6 py-3 rounded-lg hover:bg-gray-50 transition-colors;
  }
  
  .section-title {
    @apply text-2xl font-bold mb-8;
  }
}
